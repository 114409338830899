<template>
  <v-container fluid fill-height id="finishLogin" class="d-flex align-center justify-center bg-loop">

    <v-col cols="10" sm="8" md="6" lg="4">
      <v-card class="text-center pb-3" :loading="'white'">
          <div class="login-container" v-if="!showEmail">
            <br>
            <img class="logo" alt="friendly keybot ready to help you make keys" src="@/assets/Logo.png">
            <h2 class="white--text">making login magical <img class="wand" fill="#fff" src="@/assets/magic-wand.svg"></h2>
          </div>
          <div class="login-container" v-if="showEmail">
            <h2 class="white--text">Oops, I sorta lost your email in the magic...</h2>
            <v-text-field
            label="Email"
            v-model="email"
            ></v-text-field>
            <v-btn block large class="primary" v-on:click="login">Confirm Email</v-btn>
          </div>
        <!-- <v-progress-linear :indeterminate="true" color="black"></v-progress-linear> -->
      </v-card>
    </v-col>

  </v-container>
</template>

<script>
import {auth} from '@/firebase'
export default {
  name: 'emailAuth',
  data(){
    return{
        // lockJson : lockJson,
        email: null,
        showEmail: false,
        url: null,
    }
  },
  mounted(){

    this.finishLogin();
  },
  methods:{
    finishLogin(){
      let vThis = this;

      vThis.url = `${process.env.VUE_APP_SIGNIN_URL}${this.$route.fullPath}`
      // vThis.url = `172.16.42.33:8080${this.$route.fullPath}`

      // process.env.NODE_ENV === "development" ? console.log(this.$route) : null
      process.env.NODE_ENV === "development" ? console.log(window.location.href) : null
      process.env.NODE_ENV === "development" ? console.log(vThis.$route.fullPath) : null
      process.env.NODE_ENV === "development" ? console.log(vThis.url) : null
      // process.env.NODE_ENV === "development" ? console.log(window.location) : null
      // Confirm the link is a sign-in with email link.
      if (auth.isSignInWithEmailLink(vThis.$route.fullPath)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        vThis.email = window.localStorage.getItem('emailForSignIn');
        process.env.NODE_ENV === "development" ? console.log( 'email', vThis.email ) : null;
        if (vThis.email === null) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          //email = window.prompt('Please provide your email for confirmation');
          vThis.showEmail = true;
        }
        else{
          vThis.login();
        }
      }
      else{
        alert("it's not a valid link", auth.isSignInWithEmailLink(vThis.url))
      }
    },
    login(){
      let vThis = this;
      // alert(vThis.url)
      // The client SDK will parse the code from the link for you.
      auth.signInWithEmailLink(vThis.email, vThis.url)
      // You can access the new user via result.user
        .then(function(result) {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          //get user profile
          let currentUser = auth.currentUser;
          vThis.$store.dispatch('getCurrentUser', currentUser).then((userData)=>{
            if(result.additionalUserInfo.isNewUser || userData.data.showProfileFirst === true){
              vThis.$router.push('/onboarding');
            }
            else{
              vThis.$router.push('/my');
            }
          });
          // let userData = {
          //   'email': result.user.email,
          //   'id': result.user.uid,
          //   'cId': 'TODO'
          // }
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser

          // action, bus_obj, icon, color, details, user, lockId
          // vThis.$logEvent('logged in', 'user', 'person', 'secondary', 'with email', userData).then(()=>{
          //    vThis.$router.push('/keyring')
          // });
        })
        .catch(function(error) {
          alert('error', error)
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    },
    // logEvent(action, bus_obj){
    //   this.$store.dispatch('auditData/insert',  {icon: 'person', color: 'brand-dark-blue', cId: this.user.cId, action: action, bus_obj: bus_obj, created_by: this.user})
    //   .then().catch()
    // },
  },

}
</script>

<style lang="scss">
  #finishLogin{
    .v-card{
        background-color: rgba(0,0,0,.3)
    }
    .wand{
      height: 24px;
      color: #fff;
      fill: #fff;
      display: inline-block;
      margin-top: 5px;
    }
    .logo{
      max-width: 130px;
    }
    .login-container{
      width: 94%;
      margin: 0 auto;
    }
  }
</style>
